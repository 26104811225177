<div id="auth-width">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6">
       
        <div class="login-banner" style="background-color: hsla(101, 20%, 38%, 1);">
          <img src="../../../../assets/images/Onboarding.png" alt="login-banner" style=" object-fit: cover;" class="img-fluid" style="width: 100%;">
        </div>
      </div>
      <div class="col-md-6">
        <div class="login-field">
          <div class="login-box">
            <h3>Sign In</h3>
            
            <form [formGroup]="loginForm" (submit)="login()">
              <div class="form-group">
                <label for="email">Email Address <span class="required">*</span></label>

                <span class="input-icon"><img src="../../../../assets/icons/email.svg" alt="" style="margin-top: -10px;"></span>
                <input type="email" class="form-control" placeholder="Email" id="email" formControlName="email">
                <div *ngIf="submitted && f.email.errors" class="alert alert-danger">
                  <span *ngIf="f.email.errors.required">
                   The Email is Required
                  </span>
                  <span *ngIf="f.email.errors.email">
                    Invalid Email
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="email">Password<span class="required">*</span></label>

                <span class="input-icon"><img src="../../../../assets/icons/password.svg" alt=""
                    style="margin-top: -10px;"></span>
                <input [type]="hide ? 'password' : 'text'" class="form-control" placeholder="Password" id="password"
                  formControlName="password">
                <mat-icon class="placeholder" (click)="myFunction()">{{hide ? 'visibility_off' :
                  'visibility'}}</mat-icon>
                <div *ngIf="submitted && f.password.errors" class="alert alert-danger">
                  <span *ngIf="f.password.errors.required">
                   The Password is Required
                  </span>

                  <span *ngIf="f.password.errors.minlength">
                    Password must be of six or more characters
                  </span>
                </div>


                <div class="forgot-pass text-right mt-2" style="width:30%;margin-left: 68%;" routerLink="/auth/forgot-password">Forgot Password?</div>
              </div>
            

              <button type="submit" class="btn btn-primary" style="background-color: hsla(101, 20%, 38%, 1)">Sign In</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>