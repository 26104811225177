import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable } from 'rxjs';



@Injectable()
export class DashboardService {
  url: any = environment.API_URL;
  header = {};

  constructor(
    private http: HttpClient
  ) { }



  getDashboard(data: any) {
    return this.http.post(this.url + "appList/getDashboardAnalytics", data);
  }


  getDownloadAppList() {
    return this.http.get(this.url + "appList/getTotalAppsDownloaded");
  }


  getActiveUsers() {
    return this.http.get(this.url + "appList/getHospitalsUsers");
  }




  getTotalForms() {
    return this.http.get(this.url + "appList/getTotalForms");
  }

  getAgeGroup(data) {
    return this.http.post(this.url + "appList/getAgeGroup", data);
  }

  deviceCount() {
    return this.http.get(this.url + "appList/getDeviceCountByHospital");
  }

  getDeviceModel(id: any) {
    return this.http.post(this.url + "appList/getDeviceListsByHospital", id);
  }





  getManageLocalList(data: any) {
    return this.http.post(this.url + "user/admin/get", data)
  }

  getReportsDataList(skip: number, limit: number, reportType: string): Observable<any> {
    let apiUrl: string;

    skip = Math.max(0, skip); // Ensure skip is non-negative
    if (skip !== undefined && limit !== undefined) {
      apiUrl = `${this.url}adminRoute/reportList?skip=${skip}&limit=${limit}&reportType=${reportType}`;
    } else {
      apiUrl = `${this.url}adminRoute/reportList`;
    }

    return this.http.get(apiUrl);
  }

  getRegisteredDataList(skip: number, limit: number): Observable<any> {
    let apiUrl: string;

    skip = Math.max(0, skip); // Ensure skip is non-negative
    if (skip !== undefined && limit !== undefined) {
      apiUrl = `${this.url}adminRoute/registeredUsers?skip=${skip}&limit=${limit}`;
    } else {
      apiUrl = `${this.url}adminRoute/registeredUsers`;
    }

    return this.http.get(apiUrl);
  }

  getTipDataList(skip: number, limit: number, reportType: string, searchKey: any): Observable<any> {
    let apiUrl: string;

    skip = Math.max(0, skip); // Ensure skip is non-negative
    if (skip !== undefined && limit !== undefined) {
      if (searchKey) {
        apiUrl = `${this.url}adminRoute/reportList?skip=${skip}&limit=${limit}&reportType=${reportType}&searchKey=${searchKey}`;

      } else {
        apiUrl = `${this.url}adminRoute/reportList?skip=${skip}&limit=${limit}&reportType=${reportType}`;

      }
    } else {
      apiUrl = `${this.url}adminRoute/reportList`;
    }

    return this.http.get(apiUrl);
  }

  getManageLocalById(data: any) {
    return this.http.post(this.url + 'user/get', data)
  }
  getDashboardCount() {
    return this.http.get(this.url + 'admin/users-list');

  }

  getDashboardList(data: any) {
    const url = `${this.url}admin/reporting?page=${data.page}&limit=${data.limit}`;
    return this.http.post(url, data);
  }




}