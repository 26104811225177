
<div class="sidebar" style="background-color: #e5efe1;border: none;">

  
  <div class="logo mt-2"><img src="../../../assets/icons/Group 1261154395 (1).png" alt="logo" style=" object-fit: contain;height: 74px;margin-top: 20px;margin-left: 71px;margin-bottom: 35px;"></div>

  <div class="header-links">

    <a routerLink="/analytics-dashboard" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
      <span class="sidebar-icon">
          <img class="white-icon" src="../../../assets/icons/dashboard.svg" alt="" height="24" width="24">
      </span>
      <span class="link-text">Reporting</span>
  </a>

  <a routerLink="/manage-guest" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
    <span class="sidebar-icon">
        <img class="white-icon" src="../../../assets/icons/dashboard.svg" alt="" height="24" width="24">
    </span>
    <span class="link-text">Registered Consumers</span>
</a>

<a routerLink="/manage-host" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/icons/dashboard.svg" alt="" height="24" width="24">
  </span>
  <span class="link-text">Broadcast Messages</span>
</a>

<a routerLink="/privacy-policy" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/icons/dashboard.svg" alt="" height="24" width="24">
  </span>
  <span class="link-text">Privacy-Policy</span>
</a>

<a routerLink="/terms-conditions" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/icons/dashboard.svg" alt="" height="24" width="24">
  </span>
  <span class="link-text">Terms and Conditions</span>
</a>

<!-- <a routerLink="/manage-email" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/icons/dashboard.svg" alt="" height="24" width="24">
  </span>
  <span class="link-text">Email-template</span>
</a>

<a routerLink="/manage-template" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/icons/dashboard.svg" alt="" height="24" width="24">
  </span>
  <span class="link-text">Success template</span>
</a>  -->

<!-- <a routerLink="/manage-email" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/icons/dashboard.svg" alt="" height="24" width="24">
  </span>
  <span class="link-text">Email-template</span>
</a>

<a routerLink="/manage-template" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
  <span class="sidebar-icon">
      <img class="white-icon" src="../../../assets/icons/dashboard.svg" alt="" height="24" width="24">
  </span>
  <span class="link-text">Success template</span>
</a> -->


  
  <a routerLink="/edit-profile" routerLinkActive="active-link" style="color: rgba(96, 111, 140, 1);margin-bottom: 20px;">
    <span class="sidebar-icon">
      <img class="black-icon" src="../../../assets/icons/change-pass.svg" alt="" height="24" width="24">
    </span>
    <span class="link-text">Change Password</span>
  </a>
  
  <a (click)="openModal(template)" routerLinkActive="active-link"  id="logout" style="color: rgba(96, 111, 140, 1);">
      <span class="sidebar-icon">
          <img class="white-icon" src="../../../assets/icons/Logout.png" alt="" height="24" width="24">
      </span>
      <span class="link-text">Logout</span>
  </a>
   
  
<ng-template #template>

  <div class="modal-body text-center delete-modal align-items-center" style="background-color: hsla(101, 20%, 38%, 1)">
    <button type="button" class="close close-button-black-border" aria-label="Close" (click)="modalRef.hide()"
    routerLink="/careers/list">
    <span aria-hidden="true" class="logIcon">&times;</span>
</button>
      <img  src="../../../../../assets/images/create-successfull-img.svg" alt="">
      <p style="color: white;">Are you sure you <br>want to logout</p>
      <div class="modal-btn d-flex justify-content-around">
          <button class="btn btn-red" (click)="cancel()" style="color: #000;">No</button>
          <button class="btn btn-red" (click)="confirm()" style="color: #000;">Yes</button>
      </div>
  </div>
</ng-template>

  </div>
</div>



