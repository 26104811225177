

export const environment = {
  production: false,
  

   API_URL: "https://api.chewchewapp.com/v1/", 










  


  
};
