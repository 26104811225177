<app-layout></app-layout>
<div class="content">
    <div class="filter-container">
        <mat-form-field>
            <mat-label>Filter By:</mat-label>
            <mat-select [(value)]="selectedFilter" (selectionChange)="onChangeCategory($event.value)">
                <mat-option value="all">All</mat-option>
                <mat-option value="daily">Daily</mat-option>
                <mat-option value="weekly">Weekly</mat-option>
                <mat-option value="monthly">Monthly</mat-option>
                <mat-option value="annually">Annually</mat-option>
                <mat-option value="selectDateRange">Select Date Range</mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf="selectedFilter === 'selectDateRange'">
            <mat-form-field>
                <input matInput [matDatepicker]="startPicker" placeholder="Start Date" [(ngModel)]="startDate">
                <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
                <input matInput [matDatepicker]="endPicker" placeholder="End Date" [(ngModel)]="endDate" (dateChange)="saveEndDate($event)">
                <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                <mat-datepicker #endPicker></mat-datepicker>
            </mat-form-field>
        </div>
        
    </div>
    
    
    <div style="display: flex; justify-content: space-between; margin-bottom: 15px;">
        <div style="width: 45%; background-color: white; padding: 20px; border: 2px solid hsla(101, 20%, 38%, 1); border-radius: 8px; color: #000; height: 150px; margin-top: 40px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <h3 style="font-weight: bold; margin-bottom: 5px;">Total Number of Registered Consumers</h3>
            <p style="font-weight: bold;">{{resultCount}}</p>
        </div>
        <div style="width: 45%; background-color: white; padding: 20px; border: 2px solid hsla(101, 20%, 38%, 1); border-radius: 8px; color: #000; height: 150px; margin-top: 40px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <h3 style="font-weight: bold; margin-bottom: 5px;">Total Revenue Generated</h3>
        </div>
    </div>

    <div class="skill-tabs d-flex justify-content-between align-items-top">
        <div class="skill-tab-left">
            <div class="table-responsive">
                <table class="table mt-2 custom-table" aria-label="Description of the table">
                    <thead class="table-header">
                        <tr>
                            <th scope="col" class="header-text">Sl. No.</th>
                            <th scope="col" class="header-text">First Name</th>
                            <th scope="col" class="header-text">Last Name</th>
                            <th scope="col" class="header-text">Email Id</th>
                            <th scope="col" class="header-text" style="white-space: nowrap;">Mobile Number</th>

                            <!-- <th scope="col" class="header-text">Action</th>
                            <th scope="col" class="header-text">View</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let community of reportingList; let i = index">
                            <td>{{(page - 1) * itemsPerPage + i + 1}}.</td>
                            <td>{{community?.firstName ? community?.firstName : 'N/A'}}</td>
                            <td>{{community?.lastName ? community?.lastName : 'N/A'}}</td>
                            <td>{{community?.email ? community?.email : 'N/A'}}</td>
                        
                            <td>{{community?.mobileNo ? community?.mobileNo : 'N/A'}}</td>
                               
                          
                            <!-- <td>
                                <span routerLink="/manage-guest/view" style="margin-top: -10px;"
                                    [queryParams]="{ id: community._id, page: page}">
                                    <img src="../../../../../assets/icons/view-icon.svg" alt="View" />
                                </span>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
                <pagination *ngIf="!notFound && resultCount > itemsPerPage" [boundaryLinks]="true" [totalItems]="resultCount"
                    [itemsPerPage]="itemsPerPage" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)"
                    previousText="&lsaquo;" nextText="&rsaquo;" [maxSize]="10" firstText="&laquo;" lastText="&raquo;">
            </pagination>
            </div> 
        </div>
       
    </div> 

  
    
      
</div>
