<app-layout></app-layout>


<div class="content">


  <div class="edit-profile">
    <div class="row justify-content-center">
      <div class="col-md-5">
        <form [formGroup]="passwordForm">
          <div class="change-pass">
            <div class="form-group">
              <label for="">Old Password</label>
              <mat-form-field appearance="fill" class="w-100">
                <input matInput [type]="hide ? 'password' : 'text'" [(ngModel)]="passwordForm.value.oldPassword"
                  formControlName="oldPassword" (input)="checkFieldsEntered()">
                <button mat-icon-button matSuffix aria-label="Toggle password visibility" (click)="hide = !hide"
                  disableRipple>
                  <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
               
                <div class="alert alert-danger"
                  *ngIf="passwordForm.get('oldPassword') && passwordForm.get('oldPassword').hasError('required') && (passwordForm.get('oldPassword').dirty || passwordForm.get('oldPassword').touched)">
                  <span>
                    Old password is required.
                  </span>
                </div>

              </mat-form-field>
            </div>


            <div class="form-group">
              <label for="">New Password</label>
              <mat-form-field appearance="fill" class="w-100">
                <input matInput [type]="hide1 ? 'password' : 'text'" [(ngModel)]="passwordForm.value.newPassword"
                  formControlName="newPassword" (input)="checkFieldsEntered()"
                  (input)="isNewPasswordSameAsOldPassword()">
                <button mat-icon-button matSuffix aria-label="Toggle password visibility" (click)="hide1 = !hide1"
                  disableRipple>
                  <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <div class="alert alert-danger"
                  *ngIf="passwordForm.get('newPassword').invalid && passwordForm.get('newPassword').dirty">
                  <span *ngIf="passwordForm.get('newPassword').errors?.required">
                    New password is required.
                  </span>
                  <span
                    *ngIf="strongPasswordError && passwordForm.get('newPassword').errors?.pattern && !passwordForm.get('newPassword').errors?.required">
                    Please choose a stronger password. It should contain at least 6 characters, 1 lowercase letter,
                    1 uppercase letter, 1 special character, and 1 number.
                  </span>
                </div>
              </mat-form-field>
            </div>



            <div class="form-group" style="padding-top: 10px;">
              <label for="">Confirm Password</label>
              <mat-form-field appearance="fill" class="w-100">
                <input matInput [type]="hide2 ? 'password' : 'text'" [(ngModel)]="passwordForm.value.confirmPassword"
                  formControlName="confirmPassword" (input)="checkFieldsEntered()">
                <button mat-icon-button matSuffix aria-label="Toggle password visibility" (click)="hide2 = !hide2"
                  disableRipple>
                  <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <div *ngIf="passwordForm.get('confirmPassword').invalid && passwordForm.get('confirmPassword').dirty">
                  <div class="alert alert-danger" *ngIf="passwordForm.get('confirmPassword').errors?.required">
                    Confirm password is required.
                  </div>
                </div>
                <div *ngIf="passwordForm.get('confirmPassword').dirty">
                  <div class="alert alert-danger"
                    *ngIf="!passwordForm.get('confirmPassword').errors?.required && !match">
                    Please make sure your passwords match.
                  </div>
                </div>
              </mat-form-field>
            </div>




            <div class="form-group text-center">
              <button class="btn btn-blue" (click)="changePassword()"
                [disabled]="!passwordForm.valid || !allFieldsEntered || !match" style="background-color: hsla(101, 20%, 38%, 1);">
                Save
              </button>
              <p class="pass-change-msg" *ngIf="passChangeMsg">Password Changed Successfully!</p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>