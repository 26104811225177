import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { error } from 'src/app/_helpers/error';
import { AuthService } from '../../services/auth.service';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { faLock } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  faLock = faLock;
  username = new FormControl('', [Validators.required]);
  password = new FormControl('', [Validators.required]);
  submitted: boolean = false;
  error = error.err;
  loginForm: FormGroup;
  hideRequiredMarker = true;
  hide = true;
  hide1 = true;
  hide2 = true;

  userType: any = 1;

  check: number = 1;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private http: HttpClient
  ) { }



  receiveEmail: any;

  ngOnInit() {
    this.initForm();
    let a = localStorage.getItem('receiveEmail');
    let b = a?.slice(1, a.length - 1);
    this.loginForm.get("email").patchValue(b);
  }
  public showPassword: boolean = false;
  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  get f() {
    return this.loginForm.controls;
  }

  myFunction() {
    this.hide = !this.hide;
  }

  initForm() {
    this.loginForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
    });
  }


  login() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      console.log('Form is invalid', this.loginForm.value);
      return;
    }

    console.log('Form is valid', this.loginForm.value);
    this.submitted = false;
    let user: any = {
      email: this.loginForm.value.email.toLowerCase(),
      password: this.loginForm.value.password,
      userType: "ADMIN",
    };

    this.authService.login(user).subscribe(
      (res: any) => {
        if (res.statusCode == 200) {
          this.toastr.success('Logged In Successfully')

          localStorage.setItem('currentUser', JSON.stringify(res.output));

          this.router.navigateByUrl('/analytics-dashboard');
          localStorage.removeItem('receiveEmail');
        } else if (res.status == 400) {
          this.toastr.error("Invalid email or password");
        }
      },
      (err) => {
        this.toastr.error("Invalid email or password");
      }
    );
  }

}
